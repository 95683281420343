
<template>
  <div>
    <notice-bar :noticeIconColor="noticeOption.iconColor"
                :noticeIcon="noticeOption.icon"
                :noticeBackground="noticeOption.background">
      <span class="str">{{noticeOption.str}}</span>
    </notice-bar>
    <div class="center mt8 ml8 mr8">
      <van-form class="form"
                :scroll-to-error='true'
                :show-error-message='false'
                ref='form'>
        <div class="p">
          <h1>基础信息</h1>
          <van-field v-model="params.patientName"
                     ref="patientName"
                     name="就诊人"
                     label="就诊人"
                     input-align="right"
                     required
                     placeholder="请输入"
                     maxlength="20"
                     :rules="[$.rules.required]"
                     @blur="trim(params.patientName)"
                     clearable>
          </van-field>
          <van-field v-model="params.phone"
                     ref="phone"
                     type='tel'
                     name="手机号码"
                     input-align="right"
                     required
                     error
                     label="手机号码"
                     maxlength="11"
                     placeholder="请输入"
                     :rules="[$.rules.required,$.rules.oldTel(params.phone)]"
                     @blur="trim(params.phone)"
                     clearable />
          <van-field v-model="idCardType"
                     type='text'
                     name="证件类型"
                     input-align="right"
                     required
                     clickable
                     readonly
                     right-icon="arrow"
                     label="证件类型"
                     placeholder="请选择"
                     :rules="[$.rules.required]"
                     @click="idCardTypePanelShow = true" />
          <van-field v-model="params.idCardNum"
                     ref="idCardNum"
                     name="证件号"
                     input-align="right"
                     required
                     label="证件号"
                     :maxlength="params.idCardType === 1 ? '18' : null"
                     placeholder="请输入"
                     :rules="[...rules]"
                     @blur="trimCardNum(params.idCardNum)"
                     clearable />
          <van-field v-model="params.relationship"
                     type='text'
                     name="关系"
                     input-align="right"
                     required
                     clickable
                     readonly
                     label="关系"
                     placeholder="请选择"
                     right-icon="arrow"
                     :rules="[$.rules.required]"
                     @click="relationPanelShow = true" />
          <van-field v-model="sex"
                     :disabled="sexIsDiabled"
                     type='text'
                     name="性别"
                     input-align="right"
                     right-icon="arrow"
                     clickable
                     readonly
                     label="性别"
                     placeholder="请选择"
                     @click="openSexPanel" />
          <city-m v-model="city"
                  name='地址选择'
                  @change='cityChangeAction'></city-m>
        </div>
        <div class="p mt8">
          <van-field type='text'
                     v-model="editDoc"
                     name="健康档案"
                     input-align="right"
                     right-icon="arrow"
                     clickable
                     readonly
                     label="健康档案"
                     :placeholder="placeholderText"
                     @click="editRecord" />
        </div>
        <div class="p mt8">
          <van-cell center
                    title="设置默认就诊人"
                    class="default-patient">
            <template #right-icon>
              <van-switch v-model="params.setDefault"
                          :active-value="1"
                          :inactive-value="0"
                          size="24" />
            </template>
          </van-cell>
        </div>
      </van-form>
    </div>
    <div class="btn-group mt8">
      <van-button class="keep"
                  :loading="btnLoading"
                  @click='submitAction'
                  round>保存</van-button>

    </div>

    <sexPanel :sexPanelShow="sexPanelShow"
              @close="closesexPanelPop"></sexPanel>
    <idCardTypePanel :idCardTypePanelShow="idCardTypePanelShow"
                     :idCardType="idCardType"
                     @close="closeIdCardTypePanel"></idCardTypePanel>
    <relationPanel :relationPanelShow="relationPanelShow"
                   @close="closeRelationPanel"></relationPanel>
  </div>
</template>

<script>
import NoticeBar from '@/components/noticeBar'
import forms from '@/mixin/Form.js'
import button from '@/mixin/Button.js'
import cityM from '@c/cityAll'
import sexPanel from './components/sexPanel.vue'
import relationPanel from './components/relationPanel.vue'
import idCardTypePanel from './components/idCardTypePanel.vue'
export default {
  mixins: [forms, button],
  data() {
    return {
      noticeOption: {
        iconColor: '#ED6A0C',
        icon: 'info-o',
        background: '#FFFBE8',
        str: '急重症患者不适合网上咨询/问诊，请前往医院就诊',
      },
      detail: Object.create(null),
      id: this.$route.query.id,
      btnLoading: false,
      editDoc: '',
      idCardType: '身份证',
      sex: '',
      recordText: '',
      placeholderText: '选填',
      sexIsDiabled: true,
      params: {
        patientName: '',
        phone: '',
        idCardType: 1,
        idCardNum: '',
        sex: '',
        relationship: '',
        setDefault: 0,
      },
      sexPanelShow: false,
      idCardTypePanelShow: false,
      relationPanelShow: false,
      city: [], //地址选,
      rules: [this.$.rules.required, this.$.rules.peopleId],
    }
  },
  components: {
    NoticeBar,
    cityM,
    sexPanel,
    idCardTypePanel,
    relationPanel,
  },
  created() {
    console.log('=========', this.$route.name)
    if (this.$route.name === 'patient.edit') {
      this.getPatientDetail()
    }

    this.$navigation.once('back', (to, from) => {
      if (localStorage.getItem('formId')) {
        this.editDoc = '已填写完成'
      }
    })
  },
  activated() {
    if (localStorage.getItem('formId')) {
      this.editDoc = '已填写完成'
    }
  },
  destroyed() {
    console.log('111')
    localStorage.removeItem('formId')
  },
  watch: {},
  computed: {
    calcMaxlength() {
      if (params.idCardType === 1) {
        return 18
      } else if (params.idCardType === 2) {
        return 11
      } else if (params.idCardType === 3) {
        return 8
      } else if (params.idCardType === 4) {
        return 9
      }
    },
  },
  methods: {
    // 查询详情
    getPatientDetail() {
      this.$axios({
        url: 'healthy/getPatientAndDocument',
        data: {
          id: this.$route.query.id,
        },
        elseData: {
          loading: true,
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.detail = res.d
        let {
          patientName,
          phone,
          idCardType,
          idCardNum,
          sex,
          relationship,
          setDefault,
        } = res.d
        this.params.patientName = patientName
        this.params.phone = phone
        this.params.idCardType = idCardType
        this.params.idCardNum = idCardNum
        this.params.sex = sex
        this.params.relationship = relationship
        this.params.setDefault = setDefault
        if (sex === 1) {
          this.sex = '男'
        } else {
          this.sex = '女'
        }
        this.editDoc = (res.d.editDoc === 1 && '已填写完成') || ''
        this.city = [
          {
            areaCode: res.d.provinceCode,
            regionName: res.d.provinceName,
          },
          {
            areaCode: res.d.cityCode,
            regionName: res.d.cityName,
          },
          {
            areaCode: res.d.countyCode,
            regionName: res.d.countyName,
          },
        ]
        console.log(this.city)
        switch (idCardType) {
          case 1:
            this.idCardType = '身份证'
            this.rules = [this.$.rules.required, this.$.rules.peopleId]
            return
          case 2:
            this.idCardType = '港澳居民来往内地通行证'
            this.rules = [this.$.rules.required, this.$.rules.hkm]
            return
          case 3:
            this.idCardType = '台湾居民来往大陆通行证'
            this.rules = [this.$.rules.required, this.$.rules.tw]
            return
          case 4:
            this.idCardType = '护照'
            this.rules = [this.$.rules.required, this.$.rules.psp]
            return
        }
      })
    },
    formatter(value) {
      return value.replace(this.$.reg.idNum, '')
    },
    trim(str) {
      str = str.replace(/\s*/g, '')
    },
    trimCardNum(str) {
      str = str.replace(/\s*/g, '')
      if (this.params.idCardType === 1) {
        let num = this.params.idCardNum[this.params.idCardNum.length - 2]
        this.sex = num % 2 == 0 ? '女' : '男'
      }
    },
    closesexPanelPop(payload) {
      this.sexPanelShow = false
      if (!payload) return

      console.log(payload)
      this.sex = payload === 1 ? '男' : '女'
      this.params.sex = payload
    },
    closeRelationPanel(payload) {
      this.relationPanelShow = false
      if (!payload) return
      this.params.relationship = payload
    },
    closeIdCardTypePanel(payload) {
      this.idCardTypePanelShow = false
      if (!payload) return
      console.log('=================', payload)

      this.params.idCardType = payload
      this.params.idCardNum = ''
      console.log(this.params.idCardType)
      this.sex = ''
      switch (payload) {
        case 1:
          this.idCardType = '身份证'
          this.rules = [this.$.rules.required, this.$.rules.peopleId]
          console.log(this.rules)
          this.sexIsDiabled = true
          return
        case 2:
          this.idCardType = '港澳居民来往内地通行证'
          this.rules = [this.$.rules.required, this.$.rules.hkm]
          this.sexIsDiabled = false
          console.log(this.rules)
          return
        case 3:
          this.idCardType = '台湾居民来往大陆通行证'
          this.rules = [this.$.rules.required, this.$.rules.tw]
          this.sexIsDiabled = false
          console.log(this.rules)
          return
        case 4:
          this.idCardType = '护照'
          this.rules = [this.$.rules.required, this.$.rules.psp]
          this.sexIsDiabled = false
          console.log(this.rules)
          return
      }
      // console.log('=================', this.params.idCardType)
    },
    async submitAction() {
      this.appCheckFormAction()
        .then(() => {
          let submitParams = this.params
          if (localStorage.getItem('formId')) {
            submitParams.formId = localStorage.getItem('formId')
          } else {
            delete submitParams.formId
          }
          console.log(submitParams)
          if (this.$route.name === 'patient.edit') {
            submitParams.patientId = this.$route.query.id
            console.log(submitParams)
            this.$axios({
              type: 'post',
              url: 'healthy/editPatientAndDocument',
              data: submitParams,
              elseData: {
                btnLoading: true,
              },
              _self: this,
            }).then((res) => {
              console.log(res)
              console.log('新增')
              this.btnLoading = false
              if (res.data.code === 0) {
                this.$toast('保存成功')
                this.$router.go(-1)
              }
            })
          } else if (this.$route.name === 'patient.add') {
            // 如果没有参数传过来，是新增

            console.log(submitParams)
            this.$axios({
              type: 'post',
              url: 'healthy/addPatientAndDocument',
              data: submitParams,
              elseData: {
                btnLoading: true,
              },
              _self: this,
            }).then((res) => {
              console.log(res)
              console.log('编辑')
              this.btnLoading = false
              if (res.data.code === 0) {
                this.$toast('保存成功')
                this.$router.go(-1)
              }
            })
          }
        })
        .catch((err) => {
          console.log(err[0].name)
          err.forEach((i) => {
            console.log(this.$refs.patientName)
            switch (err[0].name) {
              case '就诊人':
                this.$refs.patientName.focus()
                return
              case '手机号码':
                this.$refs.phone.focus()
                return
              case '证件号':
                this.$refs.idCardNum.focus()
                return
            }
          })
        })
    },
    cityChangeAction() {
      // 城市选择赋值以及default
      // ['provinceCode', 'provinceName', 'cityCode', 'cityName', 'countyCode', 'countyName'].forEach((e, index) => {
      //   if()
      //   this.params[e] = this.city
      // })
      console.log(this.city)
      this.city.forEach((item, index) => {
        if (index === 0) {
          this.params.provinceCode = item.areaCode
          this.params.provinceName = item.regionName
        } else if (index === 1) {
          this.params.cityCode = item.areaCode
          this.params.cityName = item.regionName
        } else if (index === 2) {
          this.params.countyCode = item.areaCode || ''
          this.params.countyName = item.regionName || ''
        }
      })

      console.log(this.params)
    },
    editRecord() {
      if (this.$route.name === 'patient.add') {
        this.$router.push({
          name: 'patient.archives.add',
        })
      } else if (this.$route.name === 'patient.edit') {
        this.$router.push({
          name: 'patient.archives.edit',
          query: {
            patientId: this.$route.query.id,
          },
        })
      }
    },
    openSexPanel() {
      if (this.params.idCardType === 1) return
      this.sexPanelShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.center {
  flex: 1;
  overflow-y: auto;
}
.p {
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  h1 {
    padding: 12px 16px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .van-cell {
    background: none;
    font-size: 14px;
  }
  /deep/.van-field__label {
    margin-left: 16px;
    width: fit-content;
  }
  /deep/.van-cell--required::before {
    left: initial;
  }
  .default-patient {
    /deep/.van-cell__title {
      margin-left: 16px;
    }
  }
}
.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .keep {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>
