<!--
 * @Author: 张博洋
 * @Date: 2021-07-26 16:55:12
 * @LastEditTime: 2021-08-12 19:31:46
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/components/noticeBar.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-notice-bar :color="noticeIconColor"
                  :background="noticeBackground"
                  :left-icon="noticeIcon"
                  :scrollable="false">
    <slot></slot>
  </van-notice-bar>
</template>

<script>
import vanNoticeBar from '@/mixin/NoticeBar'
export default {
  mixins: [vanNoticeBar],
  props: {
    noticeStr: {
      type: String
    },
    noticeIcon: {
      type: String
    },
    noticeIconColor: {
      type: String
    },
    noticeBackground: {
      type: String
    }
  }
}
</script>