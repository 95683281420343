<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 18:04:53
 * @LastEditTime: 2021-12-27 10:48:14
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/patient/components/relationPanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             @close="closePanel"
             :close-on-click-overlay="true"
             :overlay="true"
             get-container="body"
             class="relation-list-panel">
    <div class="title">关系</div>
    <div class="relation flex-box"
         v-for="(item,index) in list"
         :key="index"
         @click="handleCheck(item,index)">
      <!-- <van-icon class="success"
                name="success"
                color="#1AB370"
                size="20"
                v-if="item.isChecked === 1" /> -->
      {{item.label}}
    </div>

  </van-popup>
</template>
<script>
export default {
  name: 'RelationPanel',
  props: {
    relationPanelShow: Boolean,
  },
  data() {
    return {
      show: this.relationPanelShow,
      label: '',
      list: [
        {
          label: '本人',
          isChecked: 0,
        },
        {
          label: '亲属',
          isChecked: 0,
        },
        {
          label: '朋友',
          isChecked: 0,
        },
      ],
    }
  },
  watch: {
    relationPanelShow(val) {
      this.show = val
    },
  },
  methods: {
    closePanel() {
      this.$emit('close')
    },
    closePopup() {
      console.log(this.label)

      this.$emit('close', this.label)
    },
    handleCheck(item, index) {
      this.label = item.label
      // this.list.forEach((i) => {
      //   console.log(i.isChecked)
      //   i.isChecked = 0
      // })
      console.log(item)
      // this.list[index].isChecked = 1
      // setTimeout(() => {
      //   this.closePopup()
      // }, 100)
      this.closePopup()
    },
  },
}
</script>
<style lang="scss" scoped>
.relation-list-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 32px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .relation {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    position: relative;
    border-bottom: 1px solid #f5f5f5;
    & > div {
      align-items: center;
    }

    .success {
      position: absolute;
      right: 0;
    }
  }
  .relation:last-child {
    border: none;
  }
}
</style>
