<!--
 * @Author: 张博洋
 * @Date: 2021-08-16 12:06:12
 * @LastEditTime: 2021-12-27 10:47:32
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/patient/components/idCardTypePanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             :close-on-click-overlay="true"
             @close="closePanel"
             :overlay="true"
             get-container="body"
             class="id-list-panel">
    <div class="title">证件类型</div>
    <div class="id flex-box"
         v-for="(item,index) in list"
         :key="index"
         @click="handleCheck(item,index)">
      <!-- <van-icon class="success"
                name="success"
                color="#1AB370"
                size="20"
                v-if="item.isChecked === 1" /> -->
      {{item.name}}
    </div>

  </van-popup>
</template>
<script>
export default {
  name: 'PatientListPanel',
  props: {
    idCardTypePanelShow: Boolean,
  },
  data() {
    return {
      show: this.idCardTypePanelShow,
      type: '',
      list: [
        {
          name: '身份证',
          value: 1,
        },
        {
          name: '港澳居民来往内地通行证',
          value: 2,
        },
        {
          name: '台湾居民来往大陆通行证',
          value: 3,
        },
        {
          name: '护照',
          value: 4,
        },
      ],
    }
  },
  watch: {
    idCardTypePanelShow(val) {
      this.show = val
    },
  },
  methods: {
    closePanel() {
      this.$emit('close')
    },
    closePopup() {
      console.log('关闭弹窗')
      this.$emit('close', this.type)
    },
    handleCheck(item, index) {
      console.log(item)
      this.type = item.value
      // this.list.forEach((i) => {
      //   console.log(i.isChecked)
      //   i.isChecked = 0
      // })
      // console.log(this.type)
      // this.list[index].isChecked = 1
      // setTimeout(() => {
      //   this.$emit('close', this.type)
      // }, 100)
      this.$emit('close', this.type)
    },
  },
}
</script>
<style lang="scss" scoped>
.id-list-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 32px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .id {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    position: relative;
    border-bottom: 1px solid #f5f5f5;
    & > div {
      align-items: center;
    }
    .success {
      position: absolute;
      right: 0;
    }
  }
  .id:last-child {
    border: none;
  }
}
</style>
